export function careers (): void {
  console.log(`
                          _           +----------------------------------------------+
                       ,:'/   _..._   | We are hiring! https://tails.com/gb/careers/ |
                      // ( \`""-.._.'  +----------------------------------------------+
                      \\| /    6\\___  /
                      |     6      4
                      |            /
                      \\_       .--'
                      (_'---'\`)
                      / \`'---\`()
                    ,'        |
    ,            .'\`          |
    )       _.-'              ;
   / |    .'\`   _            /
  /' /  .'       '.        , |
 /  /  /           \\   ;   | |
|   \\ |            |  .|   | |
 \\  \`"|           /.-' |   | |
  '-..-\\       _.;.._  |   |.;-.
        \\    <\`.._  )) |  .;-. ))
        (__.  \`  ))-'  \\_    ))'
           \`'--"\`       \`"""\`
`)
}
