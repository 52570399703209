
export default class Monolith {
  // DEPRECATED: Will be replaced with tracking
  private readonly hasStorage: boolean
  constructor () {
    try {
      if (window.localStorage !== undefined) {
        window.localStorage.setItem('hasStorage', 'true')
        window.localStorage.removeItem('hasStorage')
        this.hasStorage = true
        document.addEventListener('DOMContentLoaded', () => {
          const links = document.getElementById('discovery-links')
          if (links !== null) {
            this.save(links.dataset.state === 'complete' ? links.innerHTML : undefined, links.dataset.state !== 'out')
          }
        })
        return
      }
    } catch (e) {
      // Only way to check on Safari is to use it.
    }
    this.hasStorage = false
  }

  private getItem (key: string): string|null {
    if (this.hasStorage) return window.localStorage.getItem(key)
    return null
  }

  save (links: string|undefined, loggedIn: boolean): void {
    if (!this.hasStorage) return
    if (links === undefined) {
      window.localStorage.removeItem('links')
    } else {
      window.localStorage.setItem('links', links)
    }
    if (loggedIn) {
      window.localStorage.setItem('loggedIn', 'true')
    } else {
      window.localStorage.removeItem('loggedIn')
    }
  }

  get links (): string|null {
    return this.getItem('links')
  }

  get loggedIn (): boolean {
    return this.getItem('loggedIn') === 'true'
  }

  get complete (): boolean {
    return this.links !== null
  }

  clear (): void {
    this.save(undefined, false)
  }

  set (key: string, value: string): void {
    document.cookie = `${key}=${value}; Path=/; Domain=.${location.hostname}`
  }
}
