import { v4, v5 } from 'uuid'
import { push, isTails, hook } from '.'
import type { Data } from '.'

export function bindSession (backing: Data[]): void {
  const COOKIE_NAME = 'discovery'
  hook(backing, (_, update) => {
    if (isTails(update)) {
      if (update.tails.user_id) {
        push(backing, { uuid: v5(update.tails.user_id.toString(), v5.URL) }, true)
      }
      if (update.tails.uuid) {
        setCookie(COOKIE_NAME, update.tails.uuid.toString())
      }
    }
  })
  push(backing, { uuid: getCookie(COOKIE_NAME) ?? v4() }, true)
}

function setCookie (name: string, uuid: string): void {
  document.cookie = `${name}=${uuid}; Path=/; max-age=31536000`
}

function getCookie (name: string): string|undefined {
  const parts = `; ${document.cookie}`.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop()!.split(';').shift()
  }
  return undefined
}
