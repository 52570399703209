import { hook, isTails } from './index'
import type { Data, TailsData } from './index'

export function reduceTailsData (backing: Data[]): TailsData {
  const ret: TailsData = {}
  hook(backing, (_, update) => {
    if (isTails(update)) merge(update.tails, ret)
  })
  return ret
}

function isPlainObject (obj: any): boolean {
  return obj !== undefined && obj !== null && Object.prototype.toString.call(obj) === '[object Object]'
}

function merge (from: any, to: any): void {
  for (const [key, value] of Object.entries(from)) {
    if (key !== 'do_not_trigger_save') {
      if (Array.isArray(value)) {
        if (!Array.isArray(to[key])) to[key] = []
        merge(value, to[key])
      } else if (isPlainObject(value)) {
        if (!isPlainObject(to[key])) to[key] = {}
        merge(value, to[key])
      } else {
        to[key] = value
      }
    }
  }
}
