import { init, captureException } from '@sentry/browser'

init({
  dsn: window.location.hostname === 'localhost' ? /* istanbul ignore next */ undefined : 'https://d13470a1b5a142079e5def946260445f@o13887.ingest.sentry.io/1775949',
  environment: window.location.hostname,
  ignoreErrors: [
    'DataLayer Blocked',
    // IE9
    "'Uint8Array' is undefined",
    // https://github.com/getsentry/sentry/issues/5267
    'SecurityError: Blocked a frame with origin "https://tails.com" from accessing a cross-origin frame. Protocols, domains, and ports must match.',
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp,
    // https://sentry.io/organizations/tailscom/issues/1857504388/?project=104235&query=is%3Aunresolved
    "can't supply flags when constructing one RegExp from another",
    // https://sentry.io/organizations/tailscom/issues/3192675739/?project=104235
    "Can't find variable: MediaSource",
    // https://tailscom.sentry.io/issues/4338987724/?project=104235&referrer=jira_integration caused by https://github.com/getsentry/sentry-javascript/issues/3440
    'Non-Error promise rejection capture'
  ]
})

export function catcher (e: Error): void {
  captureException(e)
}

// Old API for datalayer.js
declare global {
  interface Window {
    Raven: {
      captureException: (e: Error) => void
    }
  }
}
window.Raven = {
  captureException: catcher
}
