// This file is not covered please be careful!

import 'whatwg-fetch'

import './css/main.css'
import './images/trustpilot.jpg'
import './images/trustpilot_stars.png'
import './images/b_corp.png'
import '@tailsdotcom/css/dist/logo.svg'

import { catcher } from './errors'
import Monolith from './monolith'
import { careers } from './message'
import discovery from './discovery'
import datalayer from './datalayer'
import { autoSearchParams } from './tracking/auto'
import { mappingTailsData } from './tracking/mapping'
import { bindSession } from './tracking/bind'
import { reduceTailsData } from './tracking/reduce'
// import { validateTails } from './tracking/validate'
import { runCookieConsentTool } from '@tailsdotcom/cookie-consent/dist/index'
import type { TailsData } from './tracking'

// @ts-expect-error
if (module.hot !== undefined) module.hot.accept()

declare global {
  interface Window {
    dataLayer: Array<{}>
    tailsData: TailsData
  }
}

if (window.dataLayer === undefined) window.dataLayer = []
if (window._iub === undefined) window._iub = []
if (window.optimizelyEdge === undefined) window.optimizelyEdge = []
window.optimizelyEdge.push({ type: 'holdEvents' })

// @ts-expect-error
window.dataLayer.hide = { end: () => { throw Error('DataLayer Blocked') } }
if (window.dataLayer.push.toString().includes('setTimeout(o.eventCallback, 1);')) window.dataLayer.push = Array.prototype.push.bind(window.dataLayer)

autoSearchParams(window.dataLayer)
mappingTailsData(window.dataLayer)
// validateTails(window.dataLayer)
window.tailsData = reduceTailsData(window.dataLayer)
bindSession(window.dataLayer)
window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
const monolith = new Monolith()

if (monolith.complete && window.location.pathname.length === 4) window.location.href = window.location.pathname + 'pets'

document.addEventListener('DOMContentLoaded', () => {
  try { // Keep-calm
    const urlParams = new URLSearchParams(window.location.search.slice(1))
    if (window.location.host === 'tails.com' || urlParams.has('showCookieTool')) {
      if (urlParams.has('reopenCookieTool')) {
        runCookieConsentTool(true)
      } else {
        runCookieConsentTool()
      }
    }
    discovery(monolith)
    if (document.body !== null && document.body.id === 'discovery-body') {
      document.body.addEventListener('touchstart', () => {}, { passive: true })
      // @ts-expect-error
      void import('flickity')
    }
    careers()
    datalayer()
  } catch (e) {
    catcher(e)
  }
})
