import Monolith from './monolith'
import initCountrySelection from './select-country'
import sendPageViewEventToMonolith from './page-view'

function setIfNotNull (id: string, html: string|null): void {
  const element = document.getElementById(id)
  if (element !== null && html !== null) element.innerHTML = html
}

export default function discovery (monolith: Monolith): void {
  // Send some searchParams to Monolith
  const urlParams = new URLSearchParams(window.location.search.slice(1));
  ['cc', 'pc', 'focus_group'].forEach((key) => {
    if (urlParams.has(key)) monolith.set(key, urlParams.get(key)!)
  })

  if (document.body !== null && document.body.id === 'discovery-body') {
    // Use logged in navigation if Monolith provided it
    if (monolith.loggedIn) {
      document.body.classList.add('logged-in')
    }
    if (monolith.complete) {
      document.body.classList.add('complete')
    }
    setIfNotNull('side-links', monolith.links)
    setIfNotNull('top-links', monolith.links)
  }

  // Ensure we clear before Logout (as the redirect prevents doing it after)
  Array.from(document.getElementsByClassName('signout')).forEach((link) => {
    link.addEventListener('click', (e) => {
      try {
        monolith.clear()
      } catch (_) { /* Only way to check on Safari is to use it. */ }
    })
  })

  // Navbar current links
  const currentPath = window.location.pathname
  Array.from(document.querySelectorAll('.main-link, .sub-link')).forEach((navlink) => {
    if (navlink.getAttribute('href') === (currentPath)) {
      navlink.classList.add('active')
    }
  })

  const countrySelects: HTMLSelectElement[] = Array.from(document.querySelectorAll('.country-selector-select'))
  countrySelects.forEach(select => {
    select.addEventListener('change', () => {
      document.cookie = `selected_country=${select.value.substring(1, 3).toUpperCase()}; path=/`
      /* istanbul ignore next */
      location.pathname = select.value
    })
  })

  /* istanbul ignore next */
  if (location.pathname.includes('delivery-country-selection')) {
    initCountrySelection()
  }

  sendPageViewEventToMonolith()

  /* istanbul ignore next */
  function isKeyBoardEvent (e: any): e is KeyboardEvent {
    return e.key !== undefined
  }

  /* istanbul ignore next */
  const accordionContent = document.querySelectorAll('.feature label')
  /* istanbul ignore next */
  accordionContent.forEach(content => {
    content.addEventListener('click', dropdownToggle)
    content.addEventListener('touch', dropdownToggle)
    if (content.firstElementChild) {
      // addEventListener type only accepts the `Event` type it seems
      content.firstElementChild.addEventListener('keydown', function (e: Event): void{
        if (isKeyBoardEvent(e) && e.key === 'Enter') {
          dropdownToggle()
        }
      })
    }
    function dropdownToggle (): void{
      const dropdownContent = content.nextElementSibling as HTMLElement
      if (dropdownContent.classList.contains('closed')) {
        dropdownContent.classList.remove('closed')
        dropdownContent.style.maxHeight = `${dropdownContent.scrollHeight}px`
      } else {
        dropdownContent.classList.add('closed')
        dropdownContent.style.maxHeight = '0px'
      }
    }
  })
}
