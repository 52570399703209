declare global {
  interface Window {
    tails: {
      dataLayer: {
        signupData: {
          remove: () => void
        }
      }
    }
  }
}

export default function datalayer (): void {
  // Ensure we clear before Logout (as the redirect prevents doing it after)
  Array.from(document.getElementsByClassName('signout')).forEach((link) => {
    link.addEventListener('click', (e) => {
      if (window.tails !== undefined) {
        window.tails.dataLayer.signupData.remove()
      } else {
        // We don't create `window.tails` on Discovery HTML pages.
        try {
          window.localStorage.removeItem('signupData')
        } catch (_) { /* Only way to check on Safari is to use it. */ }
      }
    })
  })
}
