import { getCookieConsentForPurpose, CookiePurposes } from '@tailsdotcom/cookie-consent/dist/index'

const sendPageViewEventToMonolith = (): void => {
  const consentGiven = getCookieConsentForPurpose(CookiePurposes.MARKETING)
  if (consentGiven) {
    if (location.pathname.split('/')[1].length === 2) {
      const url = `${location.origin}/${location.pathname.split('/')[1]}/api/facebook/track`
      void window.fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ source: location.href })
      })
    }
  }
}

export default sendPageViewEventToMonolith
