import type { Schema } from './schema.d.js'

export type TailsData = Schema

export type Data =
  | boolean
  | number
  | string
  | Data[]
  | { [key: string]: Data }

type Processor = (backing: Data[], state: Data) => void

export function isDict (data: Data): data is { [key: string]: Data } {
  return typeof data === 'object' && !(data instanceof Array)
}

export function isArray (data: Data): data is Data[] {
  return data instanceof Array
}

export function isTails (data: any): data is {tails: TailsData} {
  return isDict(data) && isDict(data.tails)
}

function processAll (backing: Data[], states: Data[], process: Processor): void {
  for (const update of states) {
    if (Array.isArray(update)) {
      processAll(backing, update, process)
    } else {
      process(backing, update)
    }
  }
}

export function hook (backing: Data[], process: Processor): void {
  const oldPush = backing.push
  backing.push = (...states) => {
    processAll(backing, states, process)
    return oldPush.apply(backing, states)
  }
  processAll(backing, [...backing], process)
}

export function push (backing: Data[], update: TailsData, skipSave?: boolean): void {
  if (skipSave) update.do_not_trigger_save = skipSave
  backing.push({ tails: update as Data })
}
