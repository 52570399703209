import type { JSONSchema7, JSONSchema7TypeName } from 'json-schema'
import * as Tails from './schema.json'
import { isDict, isArray, hook, push } from '.'
import type { Data, TailsData } from '.'

export function mappingTailsData (backing: Data[]): void {
  hook(backing, (backing, update) => {
    if (isDict(update) && update.tailsData) {
      const newData: TailsData = {}
      let hasData = traverseSchema((Tails as JSONSchema7).properties!, update.tailsData, newData)
      if (isDict(update.tailsData) && update.tailsData.pets && isArray(update.tailsData.pets)) {
        hasData = true
        newData.pets = []
        for (const pet of update.tailsData.pets) {
          newData.pets.push({})
          traverseSchema((((Tails as JSONSchema7).properties!.pets as JSONSchema7).items as JSONSchema7).properties!, pet, newData.pets[newData.pets.length - 1])
        }
      }
      if (hasData) {
        push(backing, newData)
      }
    }
  })
}

export function coerceType (value: any, coerceType: JSONSchema7TypeName): Data {
  switch (coerceType) {
    case 'integer':
      return parseInt(value)
    case 'number':
      return parseFloat(value)
    case 'string':
      return value === null ? '' : value.toString()
    case 'boolean':
      return typeof value === 'string' ? value.toLowerCase() === 'true' : Boolean(value)
    case 'null':
    case 'object':
    case 'array':
      return value
  }
}

function copy (key: string, isDestArray: boolean, type: JSONSchema7TypeName, src: Data, data: { [key: string]: Data }): void {
  if (isArray(src)) {
    src.forEach((item: any) => {
      copy(key, isDestArray, type, item, data)
    })
  } else if (isDestArray) {
    if (data[key] === undefined) {
      data[key] = []
    }
    (data[key] as Data[]).push(coerceType(src, type))
  } else {
    data[key] = coerceType(src, type)
  }
}

function traverseSchema (schema: { [k: string]: JSONSchema7 | boolean }, src: any, dest: any): boolean {
  let hasData = false
  Object.entries(schema).forEach(([key, _item]) => {
    const item = _item as JSONSchema7
    [key, ...(!item.examples ? [] : (item.examples as string[]))].forEach((_path) => {
      const path = _path
      if (src[path] !== undefined) {
        hasData = true
        const type = ((item.type === 'array' ? (item.items as JSONSchema7).type : item.type) as JSONSchema7TypeName)
        copy(key, item.type === 'array', type, src[path], dest)
      }
    })
  })
  return hasData
}
