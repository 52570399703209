import * as Tails from './schema.json'
import { push } from '.'
import type { Data, TailsData } from '.'

export function autoSearchParams (backing: Data[]): void {
  const newData: TailsData = { searchParams: {} }
  let hasData: boolean = false
  const params = new URL(window.location.href).searchParams
  for (const key of Tails.properties.searchParams.propertyNames.enum) {
    if (params.has(key)) {
      hasData = true
      newData.searchParams![key] = { [new Date().toISOString()]: params.get(key)! }
    }
  }
  if (hasData) push(backing, newData)
}
