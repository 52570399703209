/* istanbul ignore next */
function updateStore (store: string): void {
  document.cookie = `selected_country=${store.toUpperCase()}; path=/;`

  const previousAddressCookieMatch: string[] | null = document.cookie.match(/(?:previous_address=)(.*?)(?=;|$)/)
  if (previousAddressCookieMatch?.length) {
    const previousAddress: string = previousAddressCookieMatch.pop()!
    let redirectAddress: string = previousAddress
    const previousAddressStore: string = previousAddress.split('/')[1].toLowerCase()

    if (['at', 'be', 'de', 'dk', 'fr', 'gb', 'ie', 'nl', 'se'].includes(previousAddressStore)) {
      redirectAddress = redirectAddress.replace(previousAddressStore, store)
    }

    fetch(redirectAddress)
      .then(() => {
        location.replace(redirectAddress)
      })
      .catch(() => {
        location.replace(`/${store}/`)
      })
    document.cookie = 'previous_address=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }
}

/* istanbul ignore next */
function submitDeliveryCountryChoice (): void {
  const deliveryCountryForm: HTMLFormElement | null = document.querySelector('.delivery-country-form') ?? null
  const inputs: NodeListOf<HTMLInputElement> | null = deliveryCountryForm?.querySelectorAll('input[type=radio]') ?? null
  let checkedInputValue = null
  inputs?.forEach(input => {
    if (input.checked) {
      checkedInputValue = input.value
    }
  })
  if (!checkedInputValue) {
    const notification = document.querySelector('.error-notification')
    notification?.setAttribute('style', 'display: flex;')
  } else {
    updateStore(checkedInputValue)
  }
}

/* istanbul ignore next */
function moveRadioButton (): void {
  const radioButtons = document.querySelectorAll('.country-radio-button')
  radioButtons.forEach(button => {
    const country = button.querySelector('input')?.getAttribute('id')?.substring(6)
    if (country === location.pathname.slice(1, 3)) {
      const found = button
      const parent = button.parentElement
      const first = parent?.firstChild as HTMLElement
      parent?.removeChild(button)
      parent?.insertBefore(found, first)
    }
  })
}

/* istanbul ignore next */
export default function initCountrySelection (): void {
  const page = document.querySelector('.delivery-country-selection')
  const confirmButton = document.querySelector('#delivery-country-confirm')
  const notification = document.querySelector('.error-notification')
  const notificationClose = notification?.querySelector('.error-notification-close')

  moveRadioButton()
  notificationClose?.addEventListener('click', () => notification?.setAttribute('style', 'display: none;'))
  confirmButton?.addEventListener('click', () => {
    submitDeliveryCountryChoice()
  })
  page?.addEventListener('change', ({ target }) => {
    if (target instanceof HTMLInputElement) {
      notification?.setAttribute('style', 'display: none;')
    }
  })
}
